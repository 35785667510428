<template>
  <div class="container">
    <div class="auth_login_form">
      <div class="log-card mb-4">
        <div class="dash_card_body no-padding ">

          <div v-if="isSSO">
            Login with pesapal  <i class='fa fa-spinner fa-spin '></i>
          </div>
          <div v-else>
            <form @submit.prevent="login">
              <div class="form-group">
                <label>Email Address:</label>
                <div>
                  <input type="text"  v-model="email"  @blur="v$.email.$touch" class="form-control" />
                </div>
                <div class="input_error" v-if="v$.email.$error">Email is required.</div>

              </div>

              <div class="form-group">
                <label>Password:</label>
                <div>
                  <input type="password"  v-model="password"  @blur="v$.password.$touch" class="form-control" />
                  <div class="input_error" v-if="v$.password.$error">Password is required.</div>
                </div>
              </div>

              <div class="input_error mb-4" v-if="loggingError" >
                {{errorMsg}}
              </div>


              <div class="auth-layout__options">
                <!-- <va-checkbox v-model="keepLoggedIn" class="mb-0" label="keep logged_in"/> -->
              
                <span class="tl-log">Forgot your password?</span>
                <span class="reset-tx">No worries, <router-link class="ml-1 link" :to="{name: 'recover-password'}">click here </router-link> to reset your password</span>
                
              </div>

              <div class="d-flex justify--center mt-3" v-if="!isSSO">
                <va-button type="submit"  class="btn btn-large btn-primary my-0"><span v-if="loading" v-html="spinner"></span> <span v-else> Log In </span></va-button>
              </div>
              <div class="d-flex justify--center mt-3"  v-if="!isSSO">
                <span><a :href="`${this.dashBaseUrlSSO}/sso-signin`">Login with pesapal</a></span>
              </div>
            </form>
          </div>
        
         
        
        </div>
      </div>
      <footer>
        <div class="footer-logo">
          <img src="/img/reserveport-logo.png" alt="Reserveport logo">
        </div>
        <span>Copyright © 2015-{{ new Date().getFullYear() }}. Reserveport. All rights reserved.</span>
    
      </footer>
    </div>
    

  </div>
</template>

<script>
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
export default {
  name: 'login',
  setup() {
        return { v$: useVuelidate() }
  },
  data () {
    return {
      email: '',
      password: '',
      keepLoggedIn: false,
      loggingError: false,
      errorMsg: '',
      loading: false,
      spinner: "<i class='fa fa-spinner fa-spin '></i> Loggin in...",
      isSSO:false,
    }
  },
  async mounted() {
    const loginId = this.$route.query.sso
    if(loginId !=undefined){
      this.isSSO = true
      this.ssoLogin(loginId)
    }
    console.log('login id',loginId)
  },
  computed: {
  
  },
  validations() {
        return {
            email: { required },
            password: { required }
        }
  },
  methods: {
    async getRedirectRoute(user){
      const token = user.access_token;
      const merchantId =user.user.organisation_id
      try {
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Accept': 'application/json' }
        };
        const url = `${this.dashBaseUrl}/redirect-route/${merchantId}`
        const resp = await this.axios.get(url,config)
        if(resp.status == 200){
          const responseData = resp.data.data
          if(responseData.length ==0 || responseData[user.user.role.id].redirect_route ==undefined ){
               if(user.user.role.id == this.UserRoles.admin || user.user.role.id == this.UserRoles.superAdmin){
                    this.$router.push({
                      name: 'dashboard'
                  })
                  }else if(user.user.role.id == this.UserRoles.callCenter){
                    this.$router.push({
                        name: 'all_orders'
                    })
                    
                  }else if(user.user.role.id == this.UserRoles.waiters){
                    this.$router.push({
                      name:'recent_orders'
                    })
                  }else{
                    this.$router.push({
                      name: 'orders'
                   })
                  }
          }else{
              this.$router.push({
                  name: responseData[user.user.role.id].redirect_route
              })           
          }
        }
      } catch (error) {
        if(user.user.role.id == this.UserRoles.admin || user.user.role.id == this.UserRoles.superAdmin){
                    this.$router.push({
                      name: 'dashboard'
                  })
                  }else if(user.user.role.id == this.UserRoles.callCenter){
                    this.$router.push({
                        name: 'all_orders'
                    })
                  }else if(user.user.role.id == this.UserRoles.waiters){
                    this.$router.push({
                      name:'recent_orders'
                    })
                  }else{
                    this.$router.push({
                      name: 'orders'
                   })
                  }
      }
      
    },
    async ssoLogin(loginId){
      const url = `${this.dashBaseUrl}/auth/get-tokens/${loginId}`
      try {
        const resp = await this.axios.get(url)
        if(resp.status == 200){
          const user = resp.data;
          this.$store.commit('loginUser',user);
          if(user.user.organisation_id !=''){
            this.$store.commit('setMerchantId',user.user.organisation_id)
          }
          this.getRedirectRoute(user)
        }
      } catch (error) {
        this.$router.push({
          name:'login'
        })        
      }
    },
    login(){
      this.loading = true;
      const formData = new FormData();
      formData.append('username', this.email);
      formData.append('password', this.password);
      const url = this.dashBaseUrl + "/auth/login";
      this.axios.post(url, formData,).then((response) => {
              if(response.status == 200){
                const user = response.data;
                this.loading = false;
                this.loggingError = false;
                const userRoleArr = [1,2,3,5,6,7,8,9];
                this.merchantID
                if(user.user.role && userRoleArr.includes(user.user.role.id)){
                  this.$store.commit('loginUser',user);
                  if(user.user.organisation_id !=''){
                    this.$store.commit('setMerchantId',user.user.organisation_id)
                  }
                  this.getRedirectRoute(user)
                }else{
                  this.loggingError = true;
                  this.errorMsg = "You are not authorized";
                }
              }
              
          })
          .catch((error) => {
              this.loading = false;
              console.log("error", error);
              if(error.response && error.response.status === 401){
                this.loggingError = true;
                this.errorMsg = "Invalid credentials";
              }
          });
    }
  },
  mixins: [ReusableDataMixins]
}
</script>
